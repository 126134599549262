'use client';
// Libraries
import {
    Field
    , FormikProps
} from 'formik';
import LogRocket from 'logrocket';
import { useRouter } from 'next/navigation';
import {
    ChangeEvent
    , FocusEvent
    , useEffect
    , useState
} from 'react';

// MUI
import {
    Email
    , Lock
    , Visibility
    , VisibilityOff
} from '@mui/icons-material';
import {
    Box
    , Button
    , Checkbox
    , Hidden
    , IconButton
    , InputAdornment
    , Stack
    , TextField
    , Typography
} from '@mui/material';

// Utils
import { pxrem } from '@/utils/pxrem';

export interface FormValues {
    email: string;
    password: string;
    rememberMe: boolean;
    clientRecaptchaToken: string;
}

export interface Props {
    emailError: string;
    resetEmailError: () => void;
    passwordError: string;
    resetPasswordError: () => void;
    googleLoggingIn: boolean;
}

const recaptchaKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY;
const testRecaptchaKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_TEST_KEY;

const LoginForm = ( props: FormikProps<FormValues> & Props ) => {
    const {
        values: {
            email
            , password
        }
        , errors
        , touched
        , handleSubmit
        , handleChange
        , isValid
        , isSubmitting
        , setFieldError
        , setFieldTouched
        , emailError
        , resetEmailError
        , passwordError
        , resetPasswordError
        , googleLoggingIn
        , setFieldValue
    } = props;
    const [ showPassword, toggleShowPassword ] = useState( false );

    const router = useRouter();
    const isWindowDefined = typeof window !== 'undefined';

    const change = ( name: string ) => ( e: ChangeEvent<HTMLInputElement> ) => {
        if ( name === 'email' && emailError ) resetEmailError();
        else if ( name === 'password' && passwordError ) resetPasswordError();

        e.persist();
        handleChange( e );
    };

    const blur = ( name: string ) => ( e: FocusEvent<HTMLInputElement> ) => {
        e.persist();
        handleChange( e );
        setFieldTouched( name as keyof FormValues, true, false );
    };

    const recaptchaCallback = ( response: string ) => {
        setFieldTouched( 'clientRecaptchaToken', true );
        if ( response && response.length ) {
            setFieldValue( 'clientRecaptchaToken', response );
            LogRocket.captureMessage( 'Business signup - recaptcha success' );
        } else {
            LogRocket.captureMessage( 'Business signup - recaptcha failed' );
        }
    };

    useEffect( () => {
        if ( isWindowDefined ) {
            window.recaptchaCallback = recaptchaCallback;
        }
    }, [ isWindowDefined ] );

    useEffect( () => {
        const element = document.createElement( 'script' );
        element.src = 'https://www.google.com/recaptcha/enterprise.js';
        element.async = true;
        element.defer = true;
        element.id = 'html_recaptcha';
        document.body.appendChild( element );
        return () => {
            document.body.removeChild( element );
        };
    }, [] );

    useEffect( () => {
        if ( emailError ) {
            setFieldError( 'email', emailError );
            setFieldTouched( 'email', true, false );
        }
        if ( passwordError ) {
            setFieldError( 'password', passwordError );
            setFieldTouched( 'password', true, false );
        }

    }, [ emailError, passwordError ] );

    return (
        <Stack
            component='form'
            noValidate
            onSubmit={ handleSubmit }
        >
            { /* adding hidden email and password fields to trick autofill */ }
            <Hidden>
                <TextField
                    name='hidden-email'
                    sx={ { display: 'none' } }
                    id='login-form-email-hidden'
                    aria-hidden
                    hidden
                />
                <TextField
                    name='hidden-password'
                    type='password'
                    sx={ { display: 'none' } }
                    id='login-form-password-hidden'
                    aria-hidden
                    hidden
                />
            </Hidden>
            <Stack
                direction='row'
                spacing={ 1 }
                sx={ {
                    alignItems: 'center'
                    , mb: pxrem( 20 )
                } }
            >
                <Email titleAccess='Email' />
                <TextField
                    name='email'
                    label='Email'
                    value={ email }
                    error={ touched.email && Boolean( errors.email ) }
                    helperText={ touched.email && errors.email }
                    onChange={ change( 'email' ) }
                    sx={ {
                        '& .MuiFormHelperText-root.Mui-error': {
                            fontSize: pxrem( 15 )
                        }
                    } }
                    onBlur={ blur( 'email' ) }
                    variant='standard'
                    fullWidth
                    id='login-form-email'
                />
            </Stack>
            <Stack
                direction='row'
                spacing={ 1 }
                sx={ {
                    alignItems: 'center'
                    , mb: pxrem( 20 )
                } }
            >
                <Lock titleAccess='Password' />
                <TextField
                    name='password'
                    type={ showPassword ? 'text' : 'password' }
                    label='Password'
                    value={ password }
                    variant='standard'
                    error={ touched.password && Boolean( errors.password ) }
                    helperText={ touched.password && errors.password }
                    onChange={ change( 'password' ) }
                    onBlur={ blur( 'password' ) }
                    fullWidth
                    sx={ {
                        '& .MuiFormHelperText-root.Mui-error': {
                            fontSize: pxrem( 15 )
                        }
                    } }
                    id='login-form-password'
                    slotProps={ {
                        input: {
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={ () => toggleShowPassword( !showPassword ) }>
                                        {
                                            showPassword
                                                ? (
                                                    <Visibility titleAccess='Turn Show Password Off' />
                                                )
                                                : (
                                                    <VisibilityOff titleAccess='Turn Show Password On' />
                                                )
                                        }
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    } }
                />
            </Stack>
            <Stack
                direction='row'
                sx={ {
                    justifyContent: 'space-between'
                    , alignItems: 'center'
                } }
            >
                <Stack
                    direction='row'
                    sx={ { alignItems: 'center' } }
                >
                    <Field
                        name='rememberMe'
                        type='checkbox'
                        as={ Checkbox }
                        id='login-form-remember-me'
                    />
                    <Typography
                        component='label'
                        htmlFor='login-form-remember-me'
                    >
                        Remember Me
                    </Typography>
                </Stack>
                <Typography
                    sx={ {
                        color: theme => theme.palette.primary.main
                        , cursor: 'pointer'
                    } }
                    onClick={ () => router.push( '/reset-password' ) }
                >
                    Forgot Password?
                </Typography>
            </Stack>
            <Box
                sx={ {
                    display: 'flex'
                    , flexDirection: 'row'
                    , justifyContent: 'center'
                    , width: '100%'
                    , marginBottom: pxrem( 12 )
                } }
            >
                { errors && touched && touched.clientRecaptchaToken && errors.clientRecaptchaToken }
                {
                    isWindowDefined
                    && (
                        <Box
                            className='g-recaptcha'
                            data-sitekey={ (
                                ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'qa' )
                                || ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' )
                                || ( typeof window !== 'undefined'
                                    && window.Cypress
                                    && email
                                    && email.includes( '@veryableops.com' ) ) )
                                ? testRecaptchaKey
                                : recaptchaKey
                            }
                            data-callback='recaptchaCallback'
                        />
                    )
                }
            </Box>
            <Button
                variant='contained'
                color='primary'
                type='submit'
                data-cy='login-button'
                disabled={ !isValid || isSubmitting || googleLoggingIn }
                sx={ {
                    width: '100%'
                    , marginTop: pxrem( 19.2 )
                    , marginBottom: pxrem( 10 )
                } }
                id='log-in-button'
            >
                Login
            </Button>
        </Stack>
    );
};

export default LoginForm;
