import React from 'react';

// Libraries
import Image from 'next/legacy/image';

// MUI
import {
    Stack
    , Typography
} from '@mui/material';

// Utils
import { pxrem } from '@/utils/pxrem';

interface Props {
    onClick: () => void;
}

export const MicrosoftButton = ( { onClick }: Props ) => {
    return (
        <Stack
            direction='row'
            onClick={ onClick }
            sx={ {
                alignItems: 'center'
                , gap: pxrem( 12 )
                , height: pxrem( 41 )
                , paddingX: pxrem( 12 )
                , border: `${ pxrem( 1 ) } solid #8C8C8C`
                , cursor: 'pointer'
            } }
        >
            <Image
                src='microsoft-logo.svg'
                height={ 21 }
                width={ 21 }
                alt='Microsoft Logo'
            />
            <Typography
                sx={ {
                    fontFamily: 'Segoe UI, Open Sans, sans-serif'
                    , fontWeight: 600
                    , fontSize: pxrem( 15 )
                    , color: '#5E5E5E'
                } }
            >
                Sign In with Microsoft
            </Typography>
        </Stack>
    );
};
