// MUI
'use client';
import {
    Box
    , styled
} from '@mui/material';

// Utils
import { pxrem } from '@/utils/pxrem';

export const DownloadLinkContainer = styled( Box )( ( { theme } ) => ( {
    display: 'inline-block'
    , '& a': {
        display: 'inline-block'
        , width: pxrem( 135 )
        , height: pxrem( 40 )
    }
    , '&:first-child': {
        marginRight: pxrem( 10 )
    }
} ) );
