'use client';
import {
    useCallback
    , useEffect
    , useRef
    , useState
} from 'react';

// Libraries
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import {
    Formik
    , FormikHelpers
    , FormikProps
} from 'formik';
import { useRouter } from 'next/navigation';
import {
    useDispatch
    , useSelector
} from 'react-redux';
import * as Yup from 'yup';

// MUI
import {
    Box
    , Button
    , Stack
    , Theme
    , Typography
    , useMediaQuery
} from '@mui/material';

//Components
import LoginForm, { FormValues } from '../forms/LoginForm';
import { MicrosoftButton } from './MicrosoftButton';

// Utils
import { getAccessTokenDetails } from '@/modules/SchedulerModule/utils/authUtils';
import { makeAPICall } from '@/utils/axiosInstance';
import { setCookie } from '@/utils/cookies';
import { pxrem } from '@/utils/pxrem';

// Ducks
import { selectWfmUserList } from '@/ducks/config/selectors';
import { StoreState } from '@/ducks/index';
import { login } from '@/ducks/user';
import {
    selectBusinessValidationStatus
    , selectIsLoggedIn
    , selectIsLoginPending
    , selectIsMultiBusinessUser
    , selectLoginErrorMessage
    , selectSuspensions
} from '@/ducks/user/selectors';

// Hooks
import { useBusinessId } from '@/hooks/useBusinessId';

const InitialValues = {
    email: ''
    , password: ''
    , rememberMe: true
    , clientRecaptchaToken: ''
};

const Login = () => {
    const router = useRouter();
    const formikRef = useRef<( isSubmitting: boolean ) => void>( undefined );
    const dispatch = useDispatch();
    const isMobile = useMediaQuery<Theme>( theme => theme.breakpoints.down( 'xs' ) );
    const isTablet = useMediaQuery<Theme>( theme => theme.breakpoints.down( 'md' ) );
    const LoginSchema = Yup.object().shape( {
        email: Yup.string()
            .email( 'Please enter a valid email.' )
            .required( 'Please enter your email.' )
        , password: Yup.string()
            .required( 'Please enter your password.' )
        , rememberMe: Yup.bool()
        , clientRecaptchaToken: Yup
            .string()
            .required( 'Please complete the recaptcha challenge' )
    } );

    const [ networkError, setNetworkError ] = useState( '' );
    const [ emailError, setEmailError ] = useState( '' );
    const [ passwordError, setPasswordError ] = useState( '' );
    const [ skipSessionCheck, setSkipSessionCheck ] = useState<boolean>();
    const [ loginInitiated, setLoginInitiated ] = useState( false ); // this is used as protection to not redirect users to the dashboard if they have a valid session
    const [ googleLoggingIn, setGoogleLoggingIn ] = useState( false );

    const { login: loginAsMicrosoftUser } = useMsalAuthentication( InteractionType.Silent );

    const businessId = useBusinessId();
    const loggedIn = useSelector( ( { user }: StoreState ) => selectIsLoggedIn( user ) );
    const loginPending = useSelector( ( { user }: StoreState ) => selectIsLoginPending( user ) );
    const error = useSelector( ( { user }: StoreState ) => selectLoginErrorMessage( user ) );
    const suspensions = useSelector( ( state: StoreState ) => selectSuspensions( state ) );
    const isMultiBusinessUser = useSelector( ( state: StoreState ) => selectIsMultiBusinessUser( state ) );
    const wfmUserList = useSelector( ( state: StoreState ) => selectWfmUserList( state ) );
    const businessValidationStatus = useSelector( ( state: StoreState ) => selectBusinessValidationStatus( state ) );
    const resetErrors = () => {
        setNetworkError( '' );
        setEmailError( '' );
        setPasswordError( '' );
    };

    const handleSingleBusinessUser = async () => {
        if ( businessValidationStatus === 'pending' ) {
            router.push( '/pending-approval' );
            return;
        }
        try {
            const result = await makeAPICall( {
                url: `business-portal-web/businesses/${ businessId }/onboarding-item-states`
                , isBFFEndpoint: true
                , method: 'GET'
            } );
            if ( result.percentage <= 25
                && !result.hasPostedOp
                && !isMobile
            ) {
                router.push( '/onboarding' );
                return;
            }
        } catch ( error ) {
            console.error( `Error logging in as a single business user - ${ error }` );
        }
        const fromRoute = sessionStorage.getItem( 'fromRoute' );

        const wfmBusiness = wfmUserList?.includes( businessId );
        if ( wfmBusiness ) {
            const {
                locationId
                , businessId: tokenBusinessId
            } = await getAccessTokenDetails( businessId );
            if ( tokenBusinessId === businessId ) {
                router.push( `/company/scheduler/${ locationId }/summary` );
            }
            return;
        }
        if ( fromRoute ) {
            router.push( fromRoute );
        }

        router.push( '/company/dashboard/ops-summary' );
    };

    const handleSubmit = ( values: FormValues, { setSubmitting }: FormikHelpers<FormValues> ) => {
        const {
            email
            , password
            , rememberMe
            , clientRecaptchaToken
        } = values;
        formikRef.current = setSubmitting;
        setSkipSessionCheck( !rememberMe );
        resetErrors();
        const payload: {
            email: string;
            password: string;
            clientRecaptchaToken?: string;
        } = {
            email
            , password
            , clientRecaptchaToken
        };
        dispatch( login( payload ) );
        setLoginInitiated( true );
    };

    const hasWindowAndGoogle = typeof window !== 'undefined' && !!window.google;

    const initializeGoogle = useCallback( async (): Promise<unknown> => {
        if (
            hasWindowAndGoogle
            && process.env.NEXT_PUBLIC_GOOGLE_AUTH_KEY
        ) {
            try {
                window.google.accounts.id.initialize( {
                    client_id: process.env.NEXT_PUBLIC_GOOGLE_AUTH_KEY || ''
                    , callback: ( res: { credential: string } ) => {
                        if ( !res || !res.credential ) {
                            console.error( 'There was an error with Google credentials.' );
                            return;
                        }
                        dispatch( login( { googleToken: res.credential } ) );
                        setLoginInitiated( true );
                    }
                } );
                window.google.accounts.id.renderButton(
                    document.getElementById( 'buttonBox' ),
                    {
                        theme: 'outline'
                        , size: 'large'
                        , width: '336px'
                    } // customization attributes, with width hardcoded because the asset is generated by Google and requires a specific width (which cannot be obtained from parent flex containers)
                );
            } catch ( e ) {
                if ( e && e instanceof Error ) {
                    console.error( `There was an error initializing Google - ${ e.message }` );
                }
            }
        }
        return;
    }, [
        process.env.NEXT_PUBLIC_GOOGLE_AUTH_KEY, hasWindowAndGoogle
    ] );

    useEffect( () => {
        initializeGoogle();
    }, [ hasWindowAndGoogle ] );

    useEffect( () => {
        if (
            loginInitiated
            && !loginPending
            && loggedIn
        ) {
            setCookie( 'skipSessionCheck', skipSessionCheck );
            if ( !isMultiBusinessUser ) {
                handleSingleBusinessUser();
            } else {
                router.push( '/business-select' );
            }
        } else if (
            loginInitiated
            && !loginPending
            && suspensions
            && suspensions.length
        ) {
            router.push( '/suspension' );
        } else if ( googleLoggingIn && error ) {
            setNetworkError( 'We were unable to sign in with Google. Please try again or sign in with email and password.' );
            if ( window && window.google ) {
                window.google.accounts.oauth2.revoke();
                setLoginInitiated( false );
                setGoogleLoggingIn( false );
            }
        } else if (
            loginInitiated
            && !loginPending
            && error
        ) {
            setNetworkError( error );
            if ( formikRef.current ) {
                formikRef.current( false );
            }
            if ( window && window.google ) {
                window.google.accounts?.oauth2?.revoke();
                setLoginInitiated( false );
                setGoogleLoggingIn( false );
            }
            if (
                window
                && window.grecaptcha
                && window.grecaptcha.reset
            ) {
                window.grecaptcha.reset();
            }
        }
    }, [ loggedIn, loginPending, error, suspensions ] );

    useEffect( () => {
        router.prefetch( '/create-profile' );
        router.prefetch( '/business-select' );
        router.prefetch( '/company/dashboard/ops-summary' );
        // eslint-disable-next-line
    }, [] )

    const handleMicrosoftLogin = async () => {
        const result = await loginAsMicrosoftUser( InteractionType.Popup, { scopes: [ 'User.Read' ] } );
        setLoginInitiated( true );
        if ( result?.accessToken ) {
            dispatch( login( { microsoftToken: result.accessToken } ) );
        }
    };

    return (
        <Stack
            sx={ {
                padding: {
                    xs: pxrem( 48, 32 )
                    , sm: pxrem( 48, 72 )
                }
                , minHeight: pxrem( 608 )
            } }
        >
            <Typography
                variant='h2'
                sx={ {
                    margin: pxrem( 0, 0, 5 )
                    , fontSize: {
                        xs: pxrem( 25 )
                        , sm: pxrem( 30 )
                    }
                    , color: theme => theme.palette.primary.main
                    , textAlign: 'center'
                } }
            >
                Business Login
            </Typography>
            <Box
                sx={ networkError
                    ? {
                        margin: pxrem( 25.6, 0 )
                        , textAlign: 'center'
                        , minHeight: pxrem( 41.6 )
                        , display: 'flex'
                        , justifyContent: 'center'
                        , alignItems: 'center'
                        , maxWidth: pxrem( 350 )
                    }
                    : { marginBottom: pxrem( 41.6 ) } }
            >
                { networkError
                    && (
                        <Typography
                            variant='h6'
                            sx={ {
                                fontWeight: 400
                                , color: theme => theme.palette.error.main
                            } }
                        >
                            { networkError }
                        </Typography>
                    )
                }
            </Box>
            <Formik
                initialValues={ InitialValues }
                onSubmit={ handleSubmit }
                myRef={ formikRef }
                validateOnMount
                validationSchema={ LoginSchema }
                validateOnBlur
            >
                { ( formikProps: FormikProps<FormValues> ) => (
                    <LoginForm
                        { ...formikProps }
                        emailError={ emailError }
                        passwordError={ passwordError }
                        resetEmailError={ () => setEmailError( '' ) }
                        resetPasswordError={ () => setPasswordError( '' ) }
                        googleLoggingIn={ googleLoggingIn }
                    />
                ) }
            </Formik>
            <Stack
                direction='row'
                sx={ {
                    alignItems: 'center'
                    , mb: pxrem( 5 )
                } }
            >
                <Box
                    component='hr'
                    sx={ {
                        width: '100%'
                        , backgroundColor: theme => theme.palette.gray.disabled
                        , border: 'none'
                        , height: pxrem( .5 )
                        , '&:first-child': {
                            marginRight: pxrem( 10 )
                        }
                        , '&:nth-child(2)': {
                            marginLeft: pxrem( 10 )
                        }
                    } }
                />
                or
                <Box
                    component='hr'
                    sx={ {
                        width: '100%'
                        , backgroundColor: theme => theme.palette.gray.disabled
                        , border: 'none'
                        , height: pxrem( .5 )
                        , '&:first-child': {
                            marginRight: pxrem( 10 )
                        }
                        , '&:nth-child(2)': {
                            marginLeft: pxrem( 10 )
                        }
                    } }
                />
            </Stack>
            <Stack sx={ { gap: pxrem( 12 ) } }>
                <Box
                    sx={ {
                        maxWidth: '100%'
                        , marginTop: pxrem( 5 )
                        , width: '100%'
                        , display: 'flex'
                        , justifyContent: 'center'
                    } }
                >
                    <Box id='buttonBox' />
                </Box>
                <MicrosoftButton onClick={ handleMicrosoftLogin } />
            </Stack>
            {
                ( isMobile || isTablet )
                        && (
                            <Box
                                sx={ {
                                    marginTop: pxrem( 12 )
                                    , textAlign: 'center'
                                } }
                            >
                                <Typography
                                    component='p'
                                    sx={ { fontWeight: 500 } }
                                >
                                    Don&apos;t have an account?
                                </Typography>
                                <Button
                                    variant='outlined'
                                    onClick={ () => router.push( '/create-profile' ) }
                                    sx={ theme => ( {
                                        fontWeight: 600
                                        , height: pxrem( 35 )
                                        , marginTop: pxrem( 12 )
                                        , color: theme.palette.gray.main
                                        , borderColor: theme.palette.gray.hover
                                    } ) }
                                >
                                    Create Profile
                                </Button>
                            </Box>
                        ) }
        </Stack>
    );
};

export default Login;
