'use client';
// Libraries
import { useRouter } from 'next/navigation';

// MUI
import {
    Button
    , Stack
    , Theme
    , Typography
    , useMediaQuery
} from '@mui/material';

// Components
import AppDownloadPromo from '@/components/AppDownloadPromo/AppDownloadPromo';

//Utils
import { pxrem } from '@/utils/pxrem';

const NoAccountSuggestion = () => {
    const router = useRouter();
    const isMobile = useMediaQuery<Theme>( theme => theme.breakpoints.down( 'xs' ) );

    return (
        <Stack spacing={ 3 }>
            <Typography
                variant='h4'
                sx={ { fontSize: pxrem( 20 ) } }
            >
                Don&apos;t Have an Account?
            </Typography>
            <Typography sx={ { fontSize: pxrem( 15 ) } }>
                Create a free business profile and start building your labor pool today.
            </Typography>
            <Typography sx={ { fontSize: pxrem( 15 ) } }>
                If your business is already signed up with Veryable, contact your Administrator to receive access to your business account.
            </Typography>
            <Button
                variant='contained'
                color='primary'
                size='medium'
                onClick={ () => router.push( '/create-profile' ) }
            >
                Create Profile
            </Button>
            { !isMobile && <AppDownloadPromo includeTopMargin /> }
        </Stack>
    );
};

export default NoAccountSuggestion;
