'use client';

// Library imports
import Image from 'next/legacy/image';
import Link from 'next/link';

// Component imports
import { DownloadLinkContainer } from './styled';

// MUI imports
import { Box } from '@mui/material';

// Utility imports
import { pxrem } from '@/utils/pxrem';

const AppDownload = () => {
    return (
        <Box>
            <DownloadLinkContainer>
                <Link
                    href='https://apps.apple.com/us/app/veryable/id1513421404'
                    passHref
                    legacyBehavior
                    style={ { textDecoration: 'none' } }
                >
                    <Box
                        component='a'
                        sx={ {
                            background: `url(${ '/apple_button.svg' }) no-repeat`
                            , color: 'transparent'
                        } }
                        target='_blank'
                        rel='noopener noreferrer'
                        data-cy='app-store-link'
                    >
                        <Image
                            src='/appStoreLink.png'
                            alt='App Store Link'
                            width={ 135 }
                            height={ 40 }
                        />
                    </Box>
                </Link>
            </DownloadLinkContainer>
            <DownloadLinkContainer>
                <Link
                    href='https://play.google.com/store/apps/details?id=com.veryableops.business'
                    passHref
                    legacyBehavior
                    style={ { textDecoration: 'none' } }
                >
                    <Box
                        component='a'
                        sx={ {
                            width: 'unset'
                            , background: 'url(https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png) no-repeat'
                            , backgroundSize: `${ pxrem( 646 / 250 * 60 ) } ${ pxrem( 60 ) }`
                            , backgroundPositionY: -10
                            , backgroundPositionX: -10
                            , color: 'transparent'
                        } }
                        target='_blank'
                        rel='noopener noreferrer'
                        data-cy='play-store-link'
                    >
                        <Image
                            src='/playStoreLink.png'
                            alt='Play Store Link'
                            width={ 135 }
                            height={ 40 }
                        />
                    </Box>
                </Link>
            </DownloadLinkContainer>
        </Box>
    );
};

export default AppDownload;
