'use client';

// Library imports
import React from 'react';
// Component imports
import AppDownload from './AppDownload/AppDownload';

// MUI imports
import {
    Box
    , Typography
} from '@mui/material';

// Utility imports
import { pxrem } from '@/utils/pxrem';

export interface Props {
    includePadding?: boolean;
    includeTopMargin?: boolean;
}

const AppDownloadPromo: React.FC<Props> = ( {
    includePadding = false
    , includeTopMargin = false
} ) => {
    return (
        <Box
            sx={ {
                padding: includePadding ? pxrem( 12, 24 ) : 0
                , textAlign: {
                    xs: 'center'
                    , md: 'left'
                }
            } }
        >
            <Typography
                variant='h3'
                sx={ {
                    color: 'gray.dark'
                    , marginTop: includeTopMargin ? pxrem( 32 ) : 0
                } }
            >
                Want to Go Mobile?
            </Typography>
            <Typography
                variant='h5'
                sx={ {
                    fontWeight: 400
                    , color: 'gray.main'
                    , margin: pxrem( 8, 0 )
                    , textAlign: {
                        xs: 'center'
                        , md: 'left'
                    }
                } }
            >
                Download our app if you have a profile.
            </Typography>
            <Box sx={ { marginTop: pxrem( 24 ) } }>
                <AppDownload />
            </Box>
        </Box>
    );
};

export default AppDownloadPromo;
